import Carousel from './components/Carousel.svelte';

// Wait for combocarousel_renderer to finish depositing our data
document.addEventListener('DOMContentLoaded', () => {

	let sitebannerElems = document.getElementsByClassName('site-banner__carousel')

	if (sitebannerElems) {
    window.M.theme_soon = window.M.theme_soon || {};
		
    for (const sitebannerElem of sitebannerElems) {
			const { sitebannerimages = [] } = window.M.theme_soon
			
			new Carousel ({
				target: sitebannerElem,
				props: {
          images: sitebannerimages,
					autoSlide: 5000
				}
			})
		}
	}
})
